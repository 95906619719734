<template>
  <div class="product-container">
    <div
      class="product-swiper"
      :style="'height:' + swiperHeight + 'px'"
    />
    <!-- 产品类型 -->
    <div class="product-type">
      <a
        class="product-type-item it"
        href="#product"
      >
        <svg
          class="icon"
          aria-hidden="true"
        >
          <use xlink:href="#icon-ziyuan8" />
        </svg>
        <span>软件产品</span>
      </a>
      <a
        class="product-type-item train"
        href="#train"
      >
        <svg
          class="icon"
          aria-hidden="true"
        >
          <use xlink:href="#icon-ziyuan7" />
        </svg>
        <span>教育培训</span>
      </a>
      <a
        class="product-type-item outsource"
        href="#outsource"
      >
        <svg
          class="icon"
          aria-hidden="true"
        >
          <use xlink:href="#icon-ziyuan10" />
        </svg>
        <span>外包推介</span>
      </a>
      <!-- <div class="product-type-item fuhua">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-ziyuan8"></use>
        </svg>
        <span>孵化</span>
      </div> -->
    </div>
    <!-- 产品系列 -->
    <div
      id="product"
      class="product-case"
    >
      <div class="product-case-title">
        <h3>产品系列</h3>
        <p>/</p>
        <span>Product plan</span>
      </div>
      <div class="product-case-list">
        <!-- 教育培训 -->
        <div class="case-list-item">
          <h3>教育培训</h3>
          <p>
            <span>HR从业培训</span>
            <span>财税培训咨询</span>
            <span>企业管理咨询</span>
          </p>
        </div>
        <!-- 劳务外包 -->
        <div class="case-list-item">
          <h3>劳务外包</h3>
          <p>
            <span>结算</span>
            <span>匹配</span>
            <span>服务</span>
          </p>
        </div>
        <!-- 行业产品 -->
        <div class="case-list-item">
          <h3>行业产品</h3>
          <p>
            <span>HRM软件</span>
            <span>打工宝</span>
            <span>兼雇宝</span>
          </p>
        </div>
        <!-- 行业支持 -->
        <div class="case-list-item">
          <h3>行业支持</h3>
          <p>
            <span>劳务从业培训</span>
            <span>劳务公司孵化</span>
            <span>劳务采购评估</span>
          </p>
        </div>
      </div>
    </div>
    <!-- 人力资源服务外包 -->
    <div
      id="outsource"
      class="product-resource"
    >
      <div class="product-resource-title common-title">
        <span class="commm-title-label" />
        <div class="common-title-content">
          <span class="title-content-zh">人力资源服务外包</span>
          <span class="title-content-cn">Human resources outsourcing</span>
        </div>
        <span class="commm-title-label" />
      </div>
      <!-- 人力资源列表 -->
      <div class="product-resource-list">
        <div class="resource-list-item">
          <div class="list-item-icon icon-user" />
          <div>
            <h3>人员招聘</h3>
            <p>
              <span>灵活用工系列</span>
              <span>校园招聘服务</span>
              <span>高端猎头服务</span>
            </p>
          </div>
        </div>
        <div class="resource-list-item">
          <div class="list-item-icon icon-even" />
          <div>
            <h3>人事服务</h3>
            <p>
              <span>工资代发</span>
              <span>结算委托</span>
              <span>社保代理</span>
            </p>
          </div>
        </div>
        <div class="resource-list-item">
          <div class="list-item-icon icon-serve" />
          <div>
            <h3>专业服务</h3>
            <p>
              <span>劳务派遣服务推介</span>
              <span>劳务外包服务推介</span>
              <span>专业业务外包推介</span>
            </p>
          </div>
        </div>
        <div class="resource-list-item">
          <div class="list-item-icon icon-risk" />
          <div>
            <h3>风险管控</h3>
            <p>
              <span>劳资风险管控</span>
              <span>人身安全系统</span>
              <span>财税安全系统</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 培训课程 -->
    <div
      id="train"
      class="product-train"
    >
      <div class="product-train-title common-title">
        <span class="commm-title-label" />
        <div class="common-title-content">
          <span class="title-content-zh">培训课程</span>
          <span class="title-content-cn">Train course</span>
        </div>
        <span class="commm-title-label" />
      </div>
      <!-- 培训课程列表 -->
      <div class="product-train-list">
        <div class="train-list-item">
          <h3>HRM培训咨询</h3>
          <p>
            <span>如何提高工作技能？</span>
            <span>《慧眼识才》</span>
            <span>《快速育才》</span>
            <span>《人尽其才》</span>
          </p>
          <span class="btn">purchase Now</span>
        </div>
        <div class="train-list-item">
          <h3>财税培训咨询</h3>
          <p>
            <span>如何提高工作技能？</span>
            <span>《滚滚财源》</span>
            <span>《全员财政》</span>
            <span>《平安财富》</span>
          </p>
          <span class="btn">purchase Now</span>
        </div>
        <div class="train-list-item">
          <h3>管理培训咨询</h3>
          <p>
            <span>如何提高工作技能？</span>
            <span>《企业文化》</span>
            <span>《战略规划》</span>
            <span>《商业策划》</span>
          </p>
          <span class="btn">Purchase Now</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      swiperHeight: 0,
      clientWidth: 0,
      // 产品系列
      products: ['教育培训', 'HR从业培训', '财税培训咨询', '企业管理咨询', '劳务外包', '结算、匹配、服务', '行业产品', 'HRM软件、打工宝、兼雇宝', '行业支持', '劳务从业培训', '劳务公司孵化', '劳务采购评估']
    }
  },
  mounted () {
    this.clientWidth = document.documentElement.clientWidth || document.body.clientWidth
    this.swiperHeight = Math.round(this.clientWidth / 4)
    window.addEventListener('resize', () => {
      this.clientWidth = document.documentElement.clientWidth || document.body.clientWidth
      this.swiperHeight = Math.round(this.clientWidth / 4)
    })
  }
}
</script>
<style lang="less" scoped>
.product-swiper {
  width: 100%;
  background-image: url("../assets/Images/product.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
/* 产品类型 */
.product-type {
  width: 100%;
  height: 330px;
  background-image: url("../assets/Images/product-type.png");
  background-repeat: no-repeat;
  margin-top: 120px;
  margin-bottom: 10px;
  position: relative;
  .product-type-item {
    display: block;
    cursor: pointer;
    .icon {
      width: 130px;
      height: 130px;
    }
    span {
      display: block;
      font-size: 19px;
      color: #3495f7;
      letter-spacing: 4px;
      margin-top: 5px;
    }
  }
}
/* 产品系例 */
.product-case {
  .product-case-title {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid #c8d6da;
    h3 {
      color: #287dd0;
      font-weight: 500;
    }
    p {
      color: #287dd0;
      margin-left: 5px;
      margin-right: 6px;
    }
    span {
      color: #777;
      letter-spacing: 2px;
    }
  }
  .product-case-list {
    display: flex;
    align-items: center;
    // justify-content: space-around;
    box-sizing: border-box;
    .case-list-item {
      h3 {
        color: #297ed3;
        font-weight: normal;
      }
      p {
        span {
          display: block;
          color: #777;
          letter-spacing: 2px;
        }
      }
    }
  }
}
/* 培训课程 */
.product-train {
  .product-train-list {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    .train-list-item {
      background: #f6f9f9;
      box-sizing: border-box;
      position: relative;
      h3 {
        color: #b2babf;
        font-weight: normal;
        background: #eaeff0;
      }
      p {
        span {
          display: block;
          color: #3b3b3b;
          letter-spacing: 2px;
          &:first-child {
            color: #b2babf;
          }
        }
      }
      .btn {
        display: block;
        color: #fff;
        width: 70%;
        margin-left: 15%;
        // margin-top: 30px;
        background: #c9d6da;
        border-radius: 6px;
        cursor: pointer;
        position: absolute;
      }
      &:hover {
        h3 {
          background: #297ED3;
          color: #fff;
        }
        .btn {
          background: #297ED3;
        }
      }
    }
  }
}
/* 人力资源 */
.product-resource {
  .product-resource-list {
    width: 100%;
    display: flex;
    background: #f6f9f9;
    box-sizing: border-box;
    .resource-list-item {
      .list-item-icon {
        border-radius: 50%;
      }
      .icon-user {
        background-image: url("../assets/Images/product-user-icon.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .icon-even {
        background-image: url("../assets/Images/product-even-icon.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .icon-serve {
        background-image: url("../assets/Images/product-serve-icon.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .icon-risk {
        background-image: url("../assets/Images/product-risk-icon.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      h3 {
        color: #297ed3;
        font-weight: normal;
      }
      p {
        margin-top: -15px;
        span {
          display: block;
          color: #777;
        }
      }
      &:first-child {
        &:hover {
          .icon-user {
            background-image: url("../assets/Images/product-user-active-icon.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      &:nth-child(2) {
        &:hover {
          .icon-even {
            background-image: url("../assets/Images/product-even-active-icon.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      &:nth-child(3) {
        &:hover {
          .icon-serve {
            background-image: url("../assets/Images/product-serve-active-icon.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      &:last-child {
        &:hover {
          .icon-risk {
            background-image: url("../assets/Images/product-risk-active-icon.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
/* -----------适应pc端(大屏幕)--------------- */
@media screen and (min-width: 1160px) {
  /* 产品类型 */
  .product-type {
    display: block;
    .it {
      position: absolute;
      top: 3%;
      left: 18%;
    }
    .train {
      position: absolute;
      top: -19%;
      left: 44%;
    }
    .outsource {
      position: absolute;
      top: -8%;
      left: 70%;
    }
  }
  /* 产品系例 */
  .product-case {
    width: 1200px;
    margin: 40px auto 0;
    border: 1px solid #c8d6da;
    .product-case-title {
      padding: 0 40px;
      h3 {
        font-size: 30px;
        letter-spacing: 4px;
      }
      p {
        font-size: 38px;
      }
      span {
        font-size: 22px;
      }
    }
    .product-case-list {
      padding: 20px 50px;
      .case-list-item {
        width: 25%;
        h3 {
          letter-spacing: 2px;
          font-size: 23px;
        }
        p {
          span {
            line-height: 30px;
            font-size: 17px;
          }
        }
      }
    }
  }
  /* 培训课程 */
  .product-train {
    .product-train-list {
      width: 1200px;
      // justify-content: space-around;
      .train-list-item {
        width: 30%;
        height: 410px;
        padding-bottom: 100px;
        h3 {
          font-size: 30px;
          letter-spacing: 2px;
          margin-top: 0;
          margin-bottom: 20px;
          padding: 20px 0;
        }
        p {
          span {
            padding: 7px 0;
            font-size: 17px;
            &:first-child {
              font-size: 22px;
              margin-bottom: 10px;
              margin-top: 10px;
            }
          }
        }
        .btn {
          bottom: 40px;
          padding: 20px 0;
          font-size: 16px;
        }
      }
    }
  }
  /* 人力资源 */
  .product-resource {
    .product-resource-list {
      justify-content: center;
      padding: 80px 0;
      .resource-list-item {
        margin-left: 80px;
        margin-right: 80px;
        .list-item-icon {
          width: 160px;
          height: 160px;
        }
        h3 {
          letter-spacing: 2px;
          font-size: 27px;
        }
        p {
          span {
            line-height: 28px;
            letter-spacing: 2px;
            font-size: 17px;
          }
        }
      }
    }
  }
}
/* -----------适应pc端(中屏幕)--------------- */
@media screen and (min-width: 700px) and (max-width: 1160px) {
  /* 产品类型 */
  .product-type {
    display: block;
    .it {
      position: absolute;
      top: -16%;
      left: 10%;
    }
    .train {
      position: absolute;
      top: 13%;
      left: 46%;
    }
    .outsource {
      position: absolute;
      top: -15%;
      left: 80%;
    }
  }
  /* 产品系例 */
  .product-case {
    width: 100%;
    margin: 40px auto 0;
    border: 1px solid #c8d6da;
    .product-case-title {
      padding: 0 40px;
      h3 {
        font-size: 30px;
        letter-spacing: 4px;
      }
      p {
        font-size: 38px;
      }
      span {
        font-size: 22px;
      }
    }
    .product-case-list {
      padding: 20px 50px;
      .case-list-item {
        width: 25%;
        h3 {
          letter-spacing: 2px;
          font-size: 23px;
        }
        p {
          span {
            line-height: 30px;
            font-size: 17px;
          }
        }
      }
    }
  }
  /* 培训课程 */
  .product-train {
    .product-train-list {
      width: 100%;
      // justify-content: space-around;
      .train-list-item {
        width: 30%;
        height: 410px;
        padding-bottom: 100px;
        h3 {
          font-size: 23px;
          letter-spacing: 1px;
          margin-top: 0;
          margin-bottom: 8px;
          padding: 20px 0;
        }
        p {
          span {
            padding: 7px 0;
            font-size: 16px;
            &:first-child {
              font-size: 20px;
              margin-bottom: 5px;
              margin-top: 5px;
            }
          }
        }
        .btn {
          bottom: 40px;
          padding: 20px 0;
          font-size: 16px;
        }
      }
    }
  }
  /* 人力资源 */
  .product-resource {
    .product-resource-list {
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 80px 50px;
      .resource-list-item {
        width: 38%;
        margin-left: 6%;
        margin-right: 6%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .list-item-icon {
          width: 130px;
          height: 130px;
        }
        h3 {
          letter-spacing: 2px;
          font-size: 22px;
        }
        p {
          span {
            line-height: 28px;
            letter-spacing: 2px;
            font-size: 16px;
          }
        }
      }
    }
  }
}
/* -------------适应移动端---------------- */
@media screen and (max-width: 700px) {
  /* 产品类型 */
  .product-type {
    display: none;
  }
  /* 产品系例 */
  .product-case {
    width: 100%;
    margin: 0 auto;
    .product-case-title {
      padding: 0 0 0 25px;
      h3 {
        font-size: 20px;
        letter-spacing: 2px;
      }
      p {
        font-size: 20px;
      }
      span {
        font-size: 16px;
      }
    }
    .product-case-list {
      // padding: 10px 25px;
      flex-wrap: wrap;
      .case-list-item {
        width: 50%;
        h3 {
          letter-spacing: 1px;
          font-size: 19px;
          margin-bottom: 5px;
          margin-top: 10px;
        }
        p {
          span {
            line-height: 25px;
            font-size: 16px;
          }
        }
      }
    }
  }
  /* 培训课程 */
  .product-train {
    .product-train-list {
      width: 100%;
      .train-list-item {
        width: 60%;
        height: 380px;
        padding-bottom: 100px;
        margin-left: 20%;
        margin-right: 20%;
        margin-top: 20px;
        h3 {
          font-size: 19px;
          letter-spacing: 2px;
          margin-top: 0;
          margin-bottom: 20px;
          padding: 20px 0;
        }
        p {
          span {
            padding: 8px 0;
            font-size: 15px;
            &:first-child {
              font-size: 16px;
              margin-bottom: 5px;
              margin-top: 5px;
            }
          }
        }
        .btn {
          bottom: 35px;
          padding: 15px 0;
          font-size: 15px;
        }
      }
    }
  }
  /* 人力资源 */
  .product-resource {
    .product-resource-list {
      padding: 50px 0;
      align-items: center;
      flex-wrap: wrap;
      .resource-list-item {
        width: 38%;
        margin-left: 6%;
        margin-right: 6%;
        margin-bottom: 2px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .list-item-icon {
          width: 90px;
          height: 90px;
        }
        h3 {
          letter-spacing: 1px;
          font-size: 18px;
          margin-top: 4px;
        }
        p {
          span {
            line-height: 22px;
            letter-spacing: 1px;
            font-size: 15px;
          }
        }
      }
    }
  }
}
</style>
